import { useState } from 'react';
import { useQueryClient } from 'react-query';
import history from 'utils/helpers/history';

const useAuth = () => {
  const [isAuthed, setIsAuthed] = useState(checkForTokens());
  const queryClient = useQueryClient();

  history.listen(() => {
    if (!isAuthed && checkForTokens()) {
      setIsAuthed(true);
    } else if (isAuthed && !checkForTokens()) {
      setIsAuthed(false);
      queryClient.clear();
    }
  });

  return { isAuthed };
};

export default useAuth;

const checkForTokens = () => {
  return !(
    !localStorage.getItem('accessToken') &&
    !localStorage.getItem('refreshToken')
  );
};
