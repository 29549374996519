import { NavLink as NavLinkBase } from 'react-router-dom';
import styled from 'styled-components';

export const NavLink = styled(NavLinkBase)`
  align-items: center;
  color: ${({ $uiTheme }) => `var(--color-${$uiTheme})`};
  display: flex;
  height: 100%;
  text-decoration: none;
`;
