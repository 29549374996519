import React from 'react';
import styled from 'styled-components';

const LoadingSpinner = ({ component = '' }) => (
  <>
    <LoadingOverlay />
    <StyledLoadingSpinner component={component}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoadingSpinner>
  </>
);

export default LoadingSpinner;

const StyledLoadingSpinner = styled.div`
  height: 80px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc((100vh - 100px - 164px - 50px) / 2);
  width: 80px;
  z-index: 20;
  div {
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 5px solid var(--color-secondary-default);
    border-color: var(--color-secondary-default) transparent transparent
      transparent;
    border-radius: 50%;
    display: block;
    height: 48px;
    margin: 8px;
    position: absolute;
    width: 48px;
    z-index: 30;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;
