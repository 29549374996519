import styled, { css } from 'styled-components';

const ICON_SIZING = Object.freeze({
  xs: '12px',
  sm: '16px',
  md: '20px',
  lg: '24px',
  xlg: '28px'
});

const Icon = ({
  icon,
  onClick,
  uiTheme,
  size = 'md',
  isInverted,
  padding,
  isScaleUp
}) => {
  const containerType = onClick ? 'button' : 'div';

  return (
    <IconContainer
      data-testid={'icon'}
      as={containerType}
      onClick={e => (onClick ? onClick() : e.preventDefault())}
      isClickable={onClick}
      size={ICON_SIZING[size]}
      uiTheme={uiTheme}
      isInverted={isInverted}
      padding={padding}
      isScaleUp={isScaleUp}
    >
      {icon}
    </IconContainer>
  );
};

export default Icon;

const isClickableStyling = css`
  border: none;
  cursor: pointer;
  padding: ${({ padding }) => (padding ? padding : '8px')};
  position: relative;
  transition: 200ms ease-out;
  z-index: 1;
  &:hover::before {
    background: ${({ isScaleUp }) =>
      isScaleUp ? 'transparent' : 'var(--color-neutral-10);'};
    border-radius: 50%;
    content: '';
    height: ${({ size }) => `calc(${size} + 17px)`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms ease-out;
    width: ${({ size }) => `calc(${size} + 17px)`};
    z-index: -1;
  }
`;

const isInvertedStyling = css`
  background: ${({ uiTheme }) => `var(--color-${uiTheme})`};
`;

const isScaleUpAnimation = css`
  background: none;
  &:hover {
    transform: scale(1.1);
    transition: 200ms ease-in-out;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : '8px')};
  width: fit-content;
  ${({ isInverted }) => isInverted && isInvertedStyling}
  ${({ isClickable }) => isClickable && isClickableStyling}
  ${({ isScaleUp }) => isScaleUp && isScaleUpAnimation}
  svg {
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    stroke: ${({ uiTheme, isInverted }) =>
      isInverted
        ? `var(--color-neutral-white)`
        : uiTheme
        ? `var(--color-${uiTheme})`
        : 'currentColor'};
    fill: ${({ uiTheme, isInverted }) =>
      isInverted
        ? `var(--color-neutral-white)`
        : uiTheme
        ? `var(--color-${uiTheme})`
        : 'currentColor'};
  }
`;
