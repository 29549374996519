import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HFlex, VFlex } from 'components/Atoms/Containers/Containers';
import { ReactComponent as C1ConnectLogo } from 'assets/icons/c1-connect-light-image.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile-icon.svg';
import { NavLink } from 'components/Atoms/NavLink/NavLink';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import routes from 'utils/constants/routes';
import { useQueryClient } from 'react-query';
import history from 'utils/helpers/history';
import { authedRequests, logout } from 'utils/requests/authedRequests';
import useToggle from 'hooks/useToggle/useToggle';
import Modal from './Modal/Modal';
import { H5 } from 'components/Atoms/Headings/Headings';
import { Body1 } from 'components/Atoms/Paragraphs/Paragraphs';
import Button from './Button/Button';
import NavItem from './NavItem';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link-icon.svg';
import { destroyTokens } from 'utils/requests';

const NavBar = () => {
  const [isMenuShowing, setIsMenuShowing] = useToggle();
  const [isModalOpen, setIsModalOpen] = useToggle();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const menuRef = useRef(null);
  useClickedOutsideEvent(menuRef, () => setIsMenuShowing());

  const handleLogout = async () => {
    await authedRequests(logout());
    queryClient.clear();
    destroyTokens();
    history.push(routes.LOGOUT);
  };

  const renderLogoutModal = () => {
    return (
      <Modal
        closeModal={setIsModalOpen}
        titleComponent={<H5 noMargin>Logging Out</H5>}
      >
        <Body1 noMargin>Are you sure you want to logout?</Body1>
        <HFlex gap={'16px'}>
          <Button
            uiTheme={'danger'}
            onClick={handleLogout}
            icon={<ExternalLinkIcon />}
          >
            Log Out
          </Button>
          <Button variant={'ghost'} onClick={setIsModalOpen}>
            Cancel
          </Button>
        </HFlex>
      </Modal>
    );
  };

  const renderMenu = () => {
    return (
      <MenuContainer ref={menuRef}>
        <MenuItem>
          <NavLink to={routes.PROFILE}>Profile</NavLink>
        </MenuItem>
        <MenuItem onClick={setIsModalOpen}>Logout</MenuItem>
      </MenuContainer>
    );
  };

  return (
    <Navbar onMouseLeave={() => isMenuShowing && setIsMenuShowing()}>
      {isModalOpen && renderLogoutModal()}
      <NavChildrenContainer
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <HFlex alignItems={'center'} gap={'50px'} isFullHeight>
          <C1ConnectLogo height={'39px'} width={'141px'} fill={'#fff'} />
          <HFlex alignItems={'center'} gap={'25px'} isFullHeight>
            <NavLink to={routes.JOBS} $uiTheme={'neutral-20'}>
              <NavItem isUnderlined={pathname === routes.JOBS}>Jobs</NavItem>
            </NavLink>
            <NavLink to={routes.CANDIDATES} $uiTheme={'neutral-20'}>
              <NavItem isUnderlined={pathname === routes.CANDIDATES}>
                Candidates
              </NavItem>
            </NavLink>
          </HFlex>
        </HFlex>
        <NavItem
          onClick={() => !isMenuShowing && setIsMenuShowing()}
          isUnderlined={pathname === routes.PROFILE}
        >
          <ProfileIcon />
          {isMenuShowing && renderMenu()}
        </NavItem>
      </NavChildrenContainer>
    </Navbar>
  );
};

export default NavBar;

const Navbar = styled(HFlex)`
  background: var(--color-secondary-default);
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
`;

const NavChildrenContainer = styled(HFlex)`
  margin: 0 auto;
  padding: 0 0 0 16px;
  width: 1135px;
`;

const MenuContainer = styled(VFlex)`
  background-color: var(--color-neutral-white);
  border-radius: 2px;
  border: 1px solid var(--color-neutral-20);
  gap: 4px;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 55px;
  width: 156px;
`;

const MenuItem = styled.div`
  border-radius: 2px;
  color: var(--color-neutral-light);
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
  &:hover {
    background: var(--color-neutral-10);
  }
`;
