import styled from 'styled-components';

export const HFlex = styled.div`
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ isFluid }) => isFluid && '100%'};
  height: ${({ isFullHeight }) => isFullHeight && '100%'};
`;

export const VFlex = styled(HFlex)`
  flex-direction: column;
`;

export const CFlex = styled(HFlex)`
  align-items: center;
  justify-content: center;
`;
