import styled, { css } from 'styled-components';

const baseStyles = css`
  color: var(--color-neutral-black);
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 16px')};
  padding: 0;
  text-transform: capitalize;
`;

const overrideStyles = css`
  color: ${({ uiTheme }) => `var(--color-${uiTheme})`};
`;

export const H1 = styled.h1`
  ${baseStyles}
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;

export const H2 = styled.h2`
  ${baseStyles}
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;

export const H3 = styled.h3`
  ${baseStyles}
  font-size: 34px;
  font-weight: var(--weight-medium);
  letter-spacing: -1px;
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;

export const H4 = styled.h4`
  ${baseStyles}
  color: var(--color-neutral-default);
  font-size: 28px;
  font-weight: var(--weight-bold);
  letter-spacing: 0.25px;
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;

export const H5 = styled.h5`
  ${baseStyles}
  font-size: 24px;
  font-weight: var(--weight-medium);
  letter-spacing: 0px;
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;

export const H6 = styled.h6`
  ${baseStyles}
  color: var(--color-neutral-60);
  font-size: 18px;
  font-weight: var(--weight-medium);
  letter-spacing: 0.15px;
  ${({ uiTheme }) => uiTheme && overrideStyles}
`;
