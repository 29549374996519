import * as Sentry from '@sentry/react';

export const saveTokens = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const destroyTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  Sentry.setUser(null);
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};
