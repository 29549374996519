import styled, { css } from 'styled-components';

const baseStyles = css`
  color: var(--color-neutral-default);
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 8px')};
`;

const overideStyles = css`
  color: ${({ uiTheme }) => `var(--color-${uiTheme})`};
`;

export const Subtitle1 = styled.p`
  ${baseStyles}
  font-size: 16px;
  font-weight: var(--weight-medium);
  letter-spacing: 0.15px;
  line-height: 24px;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;

export const Subtitle2 = styled.p`
  ${baseStyles}
  font-size: 14px;
  font-weight: var(--weight-medium);
  letter-spacing: 0.1px;
  line-height: 24px;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;

export const Body1 = styled.p`
  ${baseStyles}
  color: var(--color-neutral-dark);
  font-size: 16px;
  font-weight: var(--weight-regular);
  letter-spacing: 0.5px;
  line-height: 20px;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;

export const Body2 = styled.p`
  ${baseStyles}
  color: var(--color-neutral-light);
  font-size: 14px;
  font-weight: var(--weight-regular);
  letter-spacing: 0.25px;
  line-height: 17px;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;

export const Caption = styled.p`
  ${baseStyles}
  color: var(--color-neutral-60);
  font-size: 12px;
  font-weight: ${({ bold }) =>
    bold ? 'var(--weight-medium);' : ' var(--weight-regular)'};
  letter-spacing: 0.4px;
  line-height: 16px;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;

export const Overline = styled.p`
  ${baseStyles}
  font-size: 12px;
  font-weight: var(--weight-black);
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  ${({ uiTheme }) => uiTheme && overideStyles}
`;
