import styled from 'styled-components';
import { HFlex } from 'components/Atoms/Containers/Containers';

const NavItem = ({ isUnderlined, children, onClick, gap }) => {
  return (
    <HFlexStyled
      alignItems={'center'}
      onClick={onClick}
      isUnderlined={isUnderlined}
      gap={gap}
    >
      {children}
    </HFlexStyled>
  );
};

export default NavItem;

const HFlexStyled = styled(HFlex)`
  border-bottom: 4px solid
    ${({ isUnderlined }) =>
      isUnderlined ? `var(--color-primary-default)` : 'transparent'};
  color: ${({ $uiTheme }) => `var(--color-${$uiTheme})`};
  cursor: pointer;
  height: 100%;
  padding: 4px 16px 0;
  position: relative;
  text-decoration: none;
  transition: 200ms ease-out;
  &:hover {
    border-bottom: 4px solid var(--color-primary-default);
  }
`;
