import styled from 'styled-components';
import { CFlex, HFlex, VFlex } from 'components/Atoms/Containers/Containers';
import { H1 } from 'components/Atoms/Headings/Headings';
import { Subtitle1, Subtitle2 } from 'components/Atoms/Paragraphs/Paragraphs';

const ErrorPage = () => {
  return (
    <PageWrapper justifyContent={'space-between'}>
      <ContentContainer justifyContent={'center'}>
        <H1Styled>Oops!</H1Styled>
        <Subtitle1Styled>
          Something went wrong. Please contact{' '}
          <a href="mailto:recruitment@correlation-one.com">
            recruitment@correlation-one.com
          </a>{' '}
          for help.
        </Subtitle1Styled>
        <Subtitle1Styled>
          Go to <a href="/">Home page.</a>
        </Subtitle1Styled>
        <Subtitle2Styled>Error code: 500</Subtitle2Styled>
      </ContentContainer>
      <ShapeContainer alignItems={'center'}>
        <Cable />
        <Plug />
        <PlugEnds justifyContent={'space-around'}>
          <PlugEnd />
          <PlugEnd />
        </PlugEnds>
        <Socket>
          <SocketInner alignItems={'center'} justifyContent={'space-evenly'}>
            <Circle />
            <Circle />
          </SocketInner>
        </Socket>
      </ShapeContainer>
    </PageWrapper>
  );
};

export default ErrorPage;

const PageWrapper = styled(VFlex)`
  background-color: var(--color-secondary-default);
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled(VFlex)`
  height: 100%;
  margin-left: 212px;
  width: 500px;
`;

const ShapeContainer = styled(HFlex)`
  height: 193px;
  margin-bottom: 128px;
  width: 100%;
`;

const Cable = styled.div`
  background-color: var(--color-secondary-light);
  height: 32px;
  width: 55%;
`;

const Plug = styled.div`
  background-color: var(--color-secondary-light);
  border-bottom-left-radius: 66px;
  border-top-left-radius: 66px;
  height: 132px;
  margin-left: -10px;
  width: 66px;
`;

const PlugEnds = styled(VFlex)`
  height: 132px;
  margin-left: 21px;
  width: 103px;
`;

const PlugEnd = styled.div`
  background-color: var(--color-secondary-light);
  border-radius: 12px;
  height: 24px;
  width: 82px;
`;

const Socket = styled(CFlex)`
  background-color: var(--color-secondary-light);
  border-radius: 8px;
  height: 193px;
  margin-left: 54px;
  width: 193px;
`;

const SocketInner = styled(HFlex)`
  border-radius: 8px;
  border: 8px solid var(--color-secondary-default);
  height: 143px;
  width: 143px;
`;

const Circle = styled.div`
  background-color: var(--color-secondary-default);
  border-radius: 12px;
  height: 24px;
  width: 24px;
`;

const H1Styled = styled(H1)`
  color: var(--color-neutral-white);
`;

const Subtitle1Styled = styled(Subtitle1)`
  color: var(--color-neutral-white);
  text-transform: none;
  a {
    color: var(--color-neutral-white);
    text-underline-position: under;
  }
  a:hover {
    color: var(--color-primary-light);
  }
`;

const Subtitle2Styled = styled(Subtitle2)`
  color: var(--color-primary-light);
`;
