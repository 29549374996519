import { Suspense } from 'react';
import { UnauthedApp, AuthedApp } from 'utils/helpers/lazyImport';
import useAuth from 'hooks/useAuth';
import NavBar from 'components/Blocks/NavBar';
import LoadingSpinner from 'components/Blocks/LoadingSpinner';
import { NotificationContainer } from 'components/Blocks/Notification';

const App = () => {
  const { isAuthed } = useAuth();

  const renderUnauthedApp = () => (
    <Suspense fallback={<LoadingSpinner />}>
      <UnauthedApp />
    </Suspense>
  );

  const renderAuthedApp = () => (
    <>
      <NavBar />
      <Suspense fallback={<LoadingSpinner />}>
        <NotificationContainer />
        <AuthedApp />
      </Suspense>
    </>
  );

  return !isAuthed ? renderUnauthedApp() : renderAuthedApp();
};

export default App;
