import axios from 'axios';
import { refreshToken, unauthedRequests } from './unauthedRequests';
import routes from 'utils/constants/routes';
import history from 'utils/helpers/history';
import jwt_decode from 'jwt-decode';
import { destroyTokens } from './index';

const baseURL = process.env.REACT_APP_API_URL;

// Set authed API axios instance
export const authedRequests = axios.create({ baseURL });

// Refresh token with multiple outbound async requests
// Some logic used from: https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
let isTokenRefreshing = false;
let requestBacklog = [];

const onRefreshed = newToken => {
  requestBacklog.map(cb => cb(newToken));
  requestBacklog = [];
};

const subscribeRequest = cb => {
  requestBacklog.push(cb);
};

export const getTokens = () => ({
  access: localStorage.getItem('accessToken'),
  refresh: localStorage.getItem('refreshToken')
});

export const checkIsSuperUser = () => {
  const { access } = getTokens();
  const decodeAccess = jwt_decode(access);
  return decodeAccess?.is_superuser;
};

export const getUserId = () => {
  const { access } = getTokens();
  const decodeAccess = jwt_decode(access);
  return decodeAccess?.user_id;
};

// This middleware configures the headers on a request before it's sent out
authedRequests.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  };

  return config;
});

// This middleware checks attempts to refresh the access token on a 401
authedRequests.interceptors.response.use(
  config => config,
  error => {
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;
        unauthedRequests(refreshToken())
          .then(response => {
            isTokenRefreshing = false;
            localStorage.setItem('accessToken', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
            onRefreshed(response.data.access);
          })
          .catch(() => {
            isTokenRefreshing = false;
            destroyTokens();
            history.push(routes.LOGOUT);
          });
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeRequest(newToken => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  }
);

export const logout = () => {
  // logout a user
  return { url: '/accounts/logout', method: 'post' };
};

export const getPrograms = () => {
  // gets programs for the candidates page
  return {
    url: '/employers/directories',
    method: 'get'
  };
};

export const getCandidates = (programId, urlParams) => {
  // gets candidates for the candidates page
  return {
    url: `/employers/directories/${programId}/candidates${urlParams}`,
    method: 'get'
  };
};

export const getCandidate = (programId, programCandidateId) => {
  // gets program candidate
  return {
    url: `/employers/directories/${programId}/candidates/${programCandidateId}`,
    method: 'get'
  };
};

export const getActivityLogs = jobId => {
  // gets joblisting activity log
  return {
    url: `/employers/job-listings/${jobId}/history`,
    method: 'get'
  };
};

export const saveCandidate = (programId, programCandidateId, data) => {
  // save candidate
  return {
    url: `/employers/directories/${programId}/candidates/${programCandidateId}`,
    method: 'patch',
    data
  };
};

export const getLocations = () => {
  // get loocations list
  return {
    url: `/employers/location-list`,
    method: 'get'
  };
};

export const getIndustries = () => {
  // get industries list
  return {
    url: `/employers/employer-industry-list`,
    method: 'get'
  };
};

export const getEmployers = () => {
  // get employers list
  return {
    url: `/employers/employer-list`,
    method: 'get'
  };
};

export const getRoles = () => {
  // get roles list
  return {
    url: `/employers/profession-type-list`,
    method: 'get'
  };
};

export const getSkills = () => {
  // get skills list
  return {
    url: `/skill-list`,
    method: 'get'
  };
};

export const getJobCandidates = (jobId, urlParams) => {
  // gets candidates for the job page
  return {
    url: `employers/job-listings/${jobId}/candidates?${urlParams}`,
    method: 'get'
  };
};

export const getJobCandidatesForSuperAdmin = url => {
  return {
    url,
    method: 'get'
  };
};

export const getProfile = () => {
  // get profile information
  return {
    url: '/employers/employer-profile',
    method: 'get'
  };
};

export const patchCandidateByJob = (jobId, jobCandidateId, patchData) => {
  // path candidate for the job page
  return {
    url: `employers/job-listings/${jobId}/candidates/${jobCandidateId}`,
    method: 'patch',
    data: patchData
  };
};

export const connectCandidateByJob = data => {
  //connect with job candidate
  return {
    url: '/employers/job-listing-connections',
    method: 'post',
    data
  };
};

export const connectCandidateByProgram = data => {
  //connect with program candidate
  return {
    url: '/employers/directory-connections',
    method: 'post',
    data
  };
};

export const updateProfile = data => {
  // update profile information
  return {
    url: '/employers/employer-profile',
    method: 'patch',
    data
  };
};

export const getJobs = urlParams => {
  return {
    url: `/employers/job-listings?${urlParams}`,
    method: 'get'
  };
};

export const getJoblisting = joblistingId => {
  return {
    url: `/employers/job-listings/${joblistingId}`,
    method: 'get'
  };
};

export const exportCandidates = (programId, filter) => {
  // Export candidates csv
  return {
    url: `/employers/directories/${programId}/candidates/csv?${filter}`,
    method: 'get',
    isDownloadableContent: true
  };
};

export const createJob = data => {
  return {
    url: '/employers/job-listings',
    method: 'post',
    data
  };
};

export const updateJob = data => {
  return {
    url: `/employers/job-listings/${data.id}`,
    method: 'patch',
    data
  };
};

export const deleteJob = jobId => {
  return {
    url: `/employers/job-listings/${jobId}`,
    method: 'delete'
  };
};

export const exportCandidatesByJobListing = (joblistingId, filters) => {
  return {
    url: `/employers/job-listings/${joblistingId}/candidates/csv?${filters}`,
    method: 'get',
    isDownloadableContent: true
  };
};
