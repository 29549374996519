import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* Please see https://zeroheight.com/8b70dbc1a/p/0408d4-colors for reference */
 :root {
     /* Primary Colors */
    --color-primary-dark: #DFA228; /* #E0A128, #CA9E47 */
    --color-primary-default: #FFC043;
    --color-primary-light: #FFCD69; /* #FFCC66 */
    --color-primary-60: #FFD98E;
    --color-primary-40: #FFE6B4;
    --color-primary-20: #FFF2D9;
    --color-primary-10: #FFF9E7; /* #F3EBD5 */

  /* Secondary Colors */
  --color-secondary-dark: #000016; /* #2C2C2C, */ 
  --color-secondary-default: #2A2A3B; /* #36343F, #2C2A33, #383440, #373440 */ 
  --color-secondary-light: #535264;

  /* Neutral Colors */
  --color-neutral-black: #000000;
  --color-neutral-white: #FFFFFF;
  --color-neutral-dark: #2C2A33; /* #2C2C2C */
  --color-neutral-default: #373440;
  --color-neutral-light: #5F5D66; /* #696969 */
  --color-neutral-light-steel-blue: #7594C7;
  --color-neutral-60: #87858C; /* #8C8587, #7C7C7C */
  --color-neutral-40: #AFAEB3; /* #CDCDCD, #969696 */
  --color-neutral-20: #D7D6D9; /* #E1E1E1, #DDDDDD, #CAC7D4, #D8D8D8, #DADADA, #D0D0D0, #C9C9C9 */
  --color-neutral-10: #EBEAEB; /* #EEEEEE, #ECECEC, #F2F2F2, #E2E2E2, #E5E5E5, #EAEAEA */

  /* System Colors */
  --color-danger-dark: #AA3E3D;
  --color-danger-default: #D54E4C;
  --color-danger-light: #DD7170;
  --color-success-dark: #589678;
  --color-success-default: #6EBC96;
  --color-success-light: #6EBC96;
  --color-info-default: #6180AD; /* #4585E0 */
 
    /* Transparent Color */
    --color-transparent: transparent;

  /* Font Weights */
  --weight-light: 300;
  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;
  --weight-black: 900;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    background: var(--color-neutral-10);
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding:0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`;

export default GlobalStyles;
