import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Icon from 'components/Blocks/Icon/Icon';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import { CFlex, HFlex, VFlex } from 'components/Atoms/Containers/Containers';

const Modal = ({ children, closeModal, containerWidth, titleComponent }) => {
  const modalRef = useRef(null);
  useClickedOutsideEvent(modalRef, closeModal);

  return createPortal(
    <ModalBackground>
      <ModalContainer
        ref={modalRef}
        gap={'16px'}
        containerWidth={containerWidth}
      >
        <HFlex alignItems={'baseline'}>
          <TitleContainer>{titleComponent}</TitleContainer>
          <Icon icon={<CloseIcon />} onClick={closeModal} size={'xlg'} />
        </HFlex>
        {children}
      </ModalContainer>
    </ModalBackground>,
    document.body
  );
};

export default Modal;

const ModalBackground = styled(CFlex)`
  background: rgb(0, 0, 0, 0.2);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`;

const ModalContainer = styled(VFlex)`
  background: var(--color-neutral-white);
  border-radius: 8px;
  max-height: 95%;
  max-width: 1135px;
  min-width: 500px;
  overflow-y: auto;
  padding: 32px;
  position: relative;
  width: ${({ containerWidth }) => containerWidth};
`;

const TitleContainer = styled.div`
  margin: 0px;
  width: 100%;
`;
