const routes = Object.freeze({
  // Unauthed routes
  LOGIN: '/login',
  SIGNUP: '/signup',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_REQUEST: '/password-reset-request',

  // Authed routes
  PROFILE: '/profile',
  JOBS: '/jobs',
  JOB: '/jobs/:jobId',
  CREATE_JOB: '/job/create',
  EDIT_JOB: '/job/edit/:jobId',
  CANDIDATES: '/candidates',
  PROGRAM_CANDIDATE: (id = ':id') => `/programs/${id}`,
  LOGOUT: '/logout'
});

export default routes;
