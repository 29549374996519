import axios from 'axios';
import { getRefreshToken, saveTokens } from '.';
import routes from 'utils/constants/routes';
import history from 'utils/helpers/history';

const baseURL = process.env.REACT_APP_API_URL;

// Set unauthed requests axios instance
export const unauthedRequests = axios.create({ baseURL });

// This middleware sets auth tokens in localstorage post-request
unauthedRequests.interceptors.response.use(
  response => {
    if (response.config.url === '/token') {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;
      saveTokens(accessToken, refreshToken);
      history.push(routes.JOBS);
    }

    return response;
  },
  error => Promise.reject(error)
);

export const createToken = data => {
  // gets an access token for a user
  return {
    url: '/token',
    method: 'post',
    data
  };
};

export const refreshToken = () => {
  // gets a refresh token for a user
  return {
    url: '/token/refresh',
    method: 'post',
    data: {
      refresh: getRefreshToken()
    }
  };
};

export const requestPasswordReset = data => {
  // requests a link to reset a users password
  return { url: 'accounts/reset', method: 'post', data };
};

export const resetPassword = (data, token) => {
  // resets a users password
  return { url: `accounts/change/${token}`, method: 'patch', data };
};

export const getJobById = jobId => {
  return {
    url: `/employers/job-board/${jobId}`,
    method: 'get'
  };
};
