import { lazy } from 'react';

// Lazy load authed components
const AuthedApp = lazy(() => import('pages/App/AuthedApp'));
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const PasswordResetPage = lazy(() =>
  import('pages/PasswordResetPage/PasswordResetPage')
);
const PasswordResetRequestPage = lazy(() =>
  import('pages/PasswordResetRequestPage/PasswordResetRequestPage')
);

// Lazy load unauthed components
const UnauthedApp = lazy(() => import('pages/App/UnauthedApp'));
const JobsPage = lazy(() => import('pages/JobsPage/JobsPage'));
const JobPage = lazy(() => import('pages/JobPage/JobPage'));
const CreateAndEditJobPage = lazy(() =>
  import('pages/JobsPage/CreateAndEditJobPage')
);
const ProfilePage = lazy(() => import('pages/ProfilePage/ProfilePage'));
const CandidatesPage = lazy(() =>
  import('pages/CandidatesPage/CandidatesPage')
);
const CandidatePage = lazy(() => import('pages/CandidatePage/CandidatePage'));

export {
  AuthedApp,
  UnauthedApp,
  LoginPage,
  PasswordResetPage,
  PasswordResetRequestPage,
  JobsPage,
  JobPage,
  CreateAndEditJobPage,
  ProfilePage,
  CandidatesPage,
  CandidatePage
};
