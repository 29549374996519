import styled from 'styled-components/macro';
import {
  ButtonDefault,
  ButtonGhost,
  ButtonInverted
} from 'components/Atoms/Buttons';
import Spinner from 'components/Atoms/Spinner';

const BUTTON_VARIANTS = Object.freeze({
  default: ButtonDefault,
  inverted: ButtonInverted,
  ghost: ButtonGhost
});

const Button = ({
  variant = 'default',
  isLoading,
  loadingText,
  icon,
  iconAlignment,
  uiTheme,
  isFluid,
  children,
  isDisabled,
  onClick,
  type,
  borderRadius,
  ...props
}) => {
  const RenderedButton = BUTTON_VARIANTS[variant];

  const renderButtonEnd = () => {
    return (
      <RenderedButton
        onClick={onClick}
        uiTheme={uiTheme}
        isFluid={isFluid}
        disabled={isDisabled}
        isLoading={isLoading}
        type={type}
        borderRadius={borderRadius}
        {...props}
      >
        {isLoading ? `${loadingText || children}` : children}
        {(icon || isLoading) && (
          <IconContainer iconAlignment={iconAlignment} data-testid={'btn-icon'}>
            {isLoading ? <Spinner size={16} data-testid={'spinner'} /> : icon}
          </IconContainer>
        )}
      </RenderedButton>
    );
  };

  const renderButtonStart = () => {
    return (
      <RenderedButton
        onClick={onClick}
        uiTheme={uiTheme}
        isFluid={isFluid}
        disabled={isDisabled}
        isLoading={isLoading}
        type={type}
        borderRadius={borderRadius}
        {...props}
      >
        {(icon || isLoading) && (
          <IconContainer iconAlignment={iconAlignment} data-testid={'btn-icon'}>
            {isLoading ? <Spinner size={16} data-testid={'spinner'} /> : icon}
          </IconContainer>
        )}
        {isLoading ? `${loadingText || children}` : children}
      </RenderedButton>
    );
  };

  return iconAlignment === 'end' ? renderButtonEnd() : renderButtonStart();
};

export default Button;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  ${({ iconAlignment }) =>
    iconAlignment === 'end' ? 'margin-left: 8px' : 'margin-right: 8px'};
  width: 16px;
  svg {
    fill: currentColor;
  }
`;
